import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { ClipLoader } from "react-spinners"; // For loading spinner
import Navbar from "./components/Navbar";
import Footer from "./components/Footer";
import BackToTop from "./components/BackToTop";
import Home from "./pages/Home";
import About from "./pages/About";
import Projects from "./pages/Projects";
import Blog from "./pages/Blog";
import Contact from "./pages/Contact";
import ProjectDetails from "./pages/ProjectDetails";
import BlogDetails from "./pages/BlogDetails";
import NotFound from "./pages/NotFound";
import "./App.css";

// Sample data for projects and blogs
const projects = [
  {
    id: 1,
    title: "Sales Data Dashboard",
    techStack: "Power BI, SQL, Python",
    description: "A dynamic dashboard analyzing sales trends and KPIs.",
    date: "2024-03-01",
    githubLink: "https://github.com/yourprofile/sales-dashboard",
  },
  {
    id: 2,
    title: "Customer Segmentation Model",
    techStack: "Python, Scikit-Learn, KMeans",
    description: "A machine learning model to segment customers based on behavior.",
    date: "2024-03-05",
    githubLink: "https://github.com/yourprofile/customer-segmentation",
  },
];

const blogs = [
  {
    id: 1,
    title: "How Data Science is Transforming Businesses",
    summary: "Discusses real-world applications of data science and its impact.",
    date: "2024-03-02",
  },
  {
    id: 2,
    title: "The Power of SQL in Data Analysis",
    summary: "Explores how SQL can be leveraged for powerful data insights.",
    date: "2024-03-06",
  },
];

function App() {
  const [darkMode, setDarkMode] = useState(false); // Dark mode state
  const [isLoading, setIsLoading] = useState(true); // Loading state

  // Simulate loading delay
  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
    }, 2000); // 2 seconds delay
  }, []);

  // Toggle dark mode
  const toggleDarkMode = () => {
    setDarkMode(!darkMode);
  };

  // Show loading spinner while loading
  if (isLoading) {
    return (
      <div className="loading-spinner">
        <ClipLoader color="#007bff" size={50} />
      </div>
    );
  }

  return (
    <div className={darkMode ? "dark-mode" : "light-mode"}>
      {/* Dark mode toggle button */}
      <button onClick={toggleDarkMode} className="dark-mode-toggle">
        {darkMode ? "Light Mode" : "Dark Mode"}
      </button>

      {/* Router for navigation */}
      <Router>
        <Navbar />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/projects" element={<Projects projects={projects} />} />
          <Route path="/projects/:id" element={<ProjectDetails projects={projects} />} />
          <Route path="/blog" element={<Blog blogs={blogs} />} />
          <Route path="/blog/:id" element={<BlogDetails blogs={blogs} />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="*" element={<NotFound />} />
        </Routes>
        <Footer />
        <BackToTop />
      </Router>
    </div>
  );
}

export default App;