import React from "react";
import { useParams } from "react-router-dom";
import { motion } from "framer-motion";

const ProjectDetails = ({ projects }) => {
  const { id } = useParams();
  const project = projects.find((p) => p.id === parseInt(id));

  if (!project) return <div>Project not found</div>;

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.5 }}
      className="project-details">
      <h1>{project.title}</h1>
      <p><strong>Tech Stack:</strong> {project.techStack}</p>
      <p>{project.description}</p>
      <a href={project.githubLink} target="_blank" rel="noopener noreferrer">
        View on GitHub
      </a>
    </motion.div>
  );
};

export default ProjectDetails;