import React from "react";
import { useParams } from "react-router-dom";
import { motion } from "framer-motion";

const BlogDetails = ({ blogs }) => {
  const { id } = useParams();
  const blog = blogs.find((b) => b.id === parseInt(id));

  if (!blog) return <div>Blog not found</div>;

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.5 }}
      className="blog-details">
      <h1>{blog.title}</h1>
      <p>{blog.summary}</p>
      <p><strong>Published:</strong> {blog.date}</p>
    </motion.div>
  );
};

export default BlogDetails;