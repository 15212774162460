import React from "react";
import { motion } from "framer-motion";
import Testimonials from "../components/Testimonials";

const Home = () => {
  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.5 }}
      className="home-page">
      <h1>Welcome to My Portfolio!</h1>
      <p>Explore my projects, blog posts, and more.</p>
      <Testimonials />
    </motion.div>
  );
};

export default Home;