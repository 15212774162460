import React from "react";
import Newsletter from "./Newsletter";

const Footer = () => {
  return (
    <footer className="footer">
      <Newsletter />
      <p>&copy; 2025 Let's Talk About Data. All rights reserved.</p>
      <div className="social-links">
        <a href="https://linkedin.com/company/lets-talk-about-data" target="_blank" rel="noopener noreferrer">
          LinkedIn
        </a>
        <a href="https://github.com/letstalkaboutdata" target="_blank" rel="noopener noreferrer">
          GitHub
        </a>
      </div>
    </footer>
  );
};

export default Footer;