import React from "react";

const testimonials = [
  {
    id: 1,
    name: "John Doe",
    role: "Client",
    text: "Amazing work! Delivered on time and exceeded expectations.",
  },
  {
    id: 2,
    name: "Jane Smith",
    role: "Colleague",
    text: "Highly skilled and a great team player.",
  },
];

const Testimonials = () => {
  return (
    <div className="testimonials">
      <h2>Testimonials</h2>
      <div className="testimonials-list">
        {testimonials.map((testimonial) => (
          <div key={testimonial.id} className="testimonial-card">
            <p className="testimonial-text">"{testimonial.text}"</p>
            <p className="testimonial-author">
              - {testimonial.name}, {testimonial.role}
            </p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Testimonials;