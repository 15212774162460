import React, { useState } from "react";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";
import BlogEditor from "../components/BlogEditor";

const Blog = ({ blogs }) => {
  const [search, setSearch] = useState("");
  const [sortOrder, setSortOrder] = useState("newest");
  const [currentPage, setCurrentPage] = useState(1);
  const ITEMS_PER_PAGE = 2;

  // Filter and sort blogs
  const filteredBlogs = blogs
    .filter((blog) =>
      blog.title.toLowerCase().includes(search.toLowerCase())
    )
    .sort((a, b) =>
      sortOrder === "newest" ? new Date(b.date) - new Date(a.date) : a.title.localeCompare(b.title)
    );

  const [blogPosts, setBlogPosts] = useState(blogs);

  const handleSave = (newPost) => {
    const newBlogPost = {
      id: blogPosts.length + 1,
      title: newPost.title,
      summary: newPost.content.substring(0, 100) + "...", // Truncate content for summary
      date: new Date().toISOString().split("T")[0],
    };
    setBlogPosts([...blogPosts, newBlogPost]);
  };

  // Pagination logic
  const startIndex = (currentPage - 1) * ITEMS_PER_PAGE;
  const paginatedBlogs = filteredBlogs.slice(startIndex, startIndex + ITEMS_PER_PAGE);

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.5 }}
      className="blog-page">
      <h1>Blog</h1>
      <BlogEditor onSave={handleSave} />
      <input
        type="text"
        placeholder="Search blogs..."
        value={search}
        onChange={(e) => setSearch(e.target.value)}
      />
      <select onChange={(e) => setSortOrder(e.target.value)}>
        <option value="newest">Newest First</option>
        <option value="alphabetical">Alphabetical</option>
      </select>

      {paginatedBlogs.map((blog) => (
        <div key={blog.id} className="blog-card">
          <h2>{blog.title}</h2>
          <p>{blog.summary}</p>
          <Link to={`/blog/${blog.id}`}>Read More</Link>
        </div>
      ))}

      <div className="pagination">
        <button
          onClick={() => setCurrentPage(currentPage - 1)}
          disabled={currentPage === 1}
        >
          Previous
        </button>
        <button
          onClick={() => setCurrentPage(currentPage + 1)}
          disabled={currentPage * ITEMS_PER_PAGE >= filteredBlogs.length}
        >
          Next
        </button>
      </div>
    </motion.div>
  );
};

export default Blog;