import React from "react";
import { motion } from "framer-motion";

const About = () => {
  const skills = [
    { name: "Python", level: 90 },
    { name: "SQL", level: 85 },
    { name: "Machine Learning", level: 80 },
    { name: "Data Visualization", level: 75 },
  ];

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.5 }}
      className="about-page">
      <h1>About Me</h1>
      <p>
        Hi, I'm Sonu Singh, a data scientist and AI enthusiast with a passion for
        solving real-world problems using data.
      </p>
      <h2>Skills</h2>
      <div className="skills-progress">
        {skills.map((skill, index) => (
          <div key={index} className="skill">
            <p>{skill.name}</p>
            <div className="progress-bar">
              <div
                className="progress"
                style={{ width: `${skill.level}%` }}
              ></div>
            </div>
          </div>
        ))}
      </div>
      <a href="/path/to/resume.pdf" download className="resume-link">
        Download Resume
      </a>
    </motion.div>
  );
};

export default About;